<template>
    <v-container fluid>
        <v-alert
        dense
        outlined
        type="error"
        v-if="Object.keys(validation_errors).length > 0"
        >
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>
        <v-form class="custom-form" ref="transactionForm" @submit.prevent="saveTransaction">
            <v-row dense>
                <v-col 
                    cols="12" 
                    md="3"
                    style="height: 272px"
                >
                    <v-combobox
                        label="Employee"
                        dense
                        outlined
                        hide-details
                        :items="employees"
                        v-model="selectedEmployee"
                        item-text="display_name"
                        item-value="id"
                        class="mb-1"
                        @focus="getEmployees"
                        :rules="[() => !!transaction.employee_id || '']"
                        :error-messages="validation_errors.employee_id"
                    ></v-combobox>
                    <v-card :dark="selectedEmployee ? true : false" :color="selectedEmployee ? 'primary' : '#ECEFF1'" :elevation="1">
                        <v-card-text class="text-center pa-2">
                            <v-avatar
                                color="deep-purple darken-4"
                                size="120"
                                class="mb-4"
                            >
                                <v-img v-if="selectedEmployee" :src="$store.state.host+selectedEmployee.image"></v-img>
                                <v-img v-else src=""></v-img>
                            </v-avatar>
                            <h2 class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.emp_id : 'Employee ID' }}
                            </h2>
                            <h1 class="subtitle-2">{{ selectedEmployee ? selectedEmployee.name : 'Employee name' }}</h1>
                            <p class="caption mb-0">
                                {{ selectedEmployee && selectedEmployee.designation ? selectedEmployee.designation.name : 'Designation' }}, 
                                {{ selectedEmployee && selectedEmployee.department ? selectedEmployee.department.name : 'Department' }}
                            </p>
                            <p class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.phone : 'Phone' }}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                >
                    <v-card
                        height="100%"
                        :elevation="1"
                    >
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Transaction Information</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Tr. ID</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field
                                        v-model="transaction.code"
                                        dense
                                        outlined
                                        hide-details
                                        autocomplete="off"
                                        readonly
                                        :rules="[() => !!transaction.code || '']"
                                        :error-messages="validation_errors.code"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Date</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-menu 
                                        max-width="290"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                dense
                                                readonly
                                                outlined
                                                hide-details
                                                v-on="on"
                                                v-model="transaction.date"
                                                :rules="[() => !!transaction.date || '']"
                                                :error-messages="validation_errors.date"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            no-title
                                            v-model="transaction.date"
                                            @input="menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 pr-1 caption">Tr. Type</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-select
                                        dense
                                        outlined
                                        hide-details
                                        :items="['Salary Advance', 'Deduction', 'Benefit']"
                                        :rules="[() => !!transaction.tr_type || '']"
                                        v-model="transaction.tr_type"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 pr-1 caption">Transaction Amount</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field
                                        v-model="transaction.amount"
                                        dense
                                        outlined
                                        hide-details
                                        autocomplete="off"
                                        :rules="[() => !!transaction.amount || '']"
                                        :error-messages="validation_errors.amount"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-col>
                <v-col cols="12" md="5">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Other Information</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row class="pt-2">
                                <v-col cols="4" class="py-1 caption">Process By</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-combobox
                                        label="Process By"
                                        dense
                                        outlined
                                        hide-details
                                        :items="employees"
                                        item-text="display_name"
                                        item-value="id"
                                        class="custom-form"
                                        v-model="selectedProcessBy"
                                        @focus="getEmployees"
                                        :rules="[() => !!transaction.process_by || '']"
                                        :error-messages="validation_errors.process_by"
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Remark</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-textarea
                                        dense
                                        outlined
                                        v-model="transaction.remark"
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" class="text-right">
                    <v-btn height="30px" dark color="light-blue darken-2 mr-1" :loading="loading" :elevation="1" type="submit">Save</v-btn>
                    <v-btn height="30px" dark color="deep-orange" :elevation="1" @click="resetForm">Cancel</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-row v-if="transactions.length > 0">
            <v-col cols="12" class="py-1">
                <v-data-table
                    class="custom-data-table elevation-1"
                    id="reportContent"
                    dense
                    :headers="tableHeaders"
                    :search="search"
                    :items="transactions"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 50, 100, 500, -1]
                    }"
                    :items-per-page="50"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">This Month Transactions</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-text-field
                                outlined
                                dense
                                hide-details
                                v-model="search"
                                placeholder="Search"
                                append-icon="mdi-magnify"
                                style="max-width:300px;"
                                class="custom-form"
                            >
                            </v-text-field>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-btn
                                color="primary"
                                class="white--text"
                                small
                                dense
                                height="25"
                                @click="print"
                            >
                                <v-icon left small dense> mdi-printer </v-icon>
                                Print
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom v-if="$store.state['user'].role != 'User' && !item.salary_id">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="editTransaction(item)"
                                    color="primary"
                                    v-on="on"
                                    >mdi-circle-edit-outline</v-icon
                                >
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin' && !item.salary_id">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="deleteTransaction(item)"
                                    color="error"
                                    v-on="on"
                                    >mdi-delete-circle-outline</v-icon
                                >
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data(){
        return{
            employees        : [],
            selectedEmployee : null,
            selectedProcessBy: null,
            transaction      : {
                id         : null,
                code       : '',
                employee_id: null,
                date       : this.toISOLocal().substr(0, 10),
                tr_type    : '',
                amount     : 0,
                remark     : '',
                process_by : null,
            },
            transactions     : [],
            loading          : false,
            menu             : false,
            validation_errors: {},
            tableHeaders: [
                { text: 'Tr. ID', value: 'code' },
                { text: 'Date', value: 'date' },
                { text: 'Tr. Type', value: 'tr_type' },
                { text: 'Amount', value: 'amount' },
                { text: 'Process By', value: 'process_by_name' },
                { text: 'Remark', value: 'remark' },
                { text: 'Action', value: 'action' },
            ],
            search: '',
        }
    },
    watch: {
        selectedEmployee(emp){
            this.transaction.employee_id       = null;
            this.getTransactions();
            if(!emp || emp.id == undefined){
                return;
            }
            this.transaction.employee_id     = emp.id;
        },
        selectedProcessBy(emp){
            this.transaction.process_by = null;
            if(!emp || emp.id == undefined){
                return;
            }
            this.transaction.process_by = emp.id;
        },
    },
    async created () {
        if(this.$route.params.id) {
            let transaction = await this.$store.dispatch('transaction/getTransactions', {
                apiParams: { 
                    id: this.$route.params.id 
                },
                options: {
                    returnData: true
                }
            })

            this.editTransaction(transaction[0]);
            this.selectedEmployee = {
                id          : transaction[0].employee_id,
                display_name: transaction[0].employee_name,
                name        : transaction[0].employee_name,
                phone       : transaction[0].employee_phone,
                image       : transaction[0].employee_image,
                department  : {
                    name: transaction[0].department_name,
                },
                designation: {
                    name: transaction[0].designation_name,
                },
            };
        }else{
            this.getTransactionCode();
        }
    },
    methods: {
        async getTransactionCode(){
            await this.$store.dispatch("transaction/getNewTransactionCode");
            this.transaction.code = this.$store.getters["transaction/newCode"];
        },
        async getTransactions(){
            this.transactions = [];
            if(this.selectedEmployee && this.selectedEmployee.id != undefined){

                var date      = new Date(), y = date.getFullYear(), m = date.getMonth();
                var first_day = new Date(y, m, 1);
                var last_day  = new Date(y, m + 1, 0);

                this.transactions = await this.$store.dispatch('transaction/getTransactions',{
                    apiParams: {
                        employee_id: this.selectedEmployee.id,
                        date_from  : first_day,
                        date_to    : last_day,
                    },
                    options: {
                        returnData: true,
                    }
                });
            }
        },
        async getEmployees(){
            this.employees = await this.$store.dispatch('employee/getEmployees', {
                apiParams: {},
                options: {
                    returnData: true
                }
            });
        },
        async saveTransaction(){

            if(!this.$refs.transactionForm.validate()){
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            this.loading = true;
            let url = "/add_transaction";
            if(this.transaction.id){
                url = '/update_transaction';
            }

            let data = {
                url,
                transaction: this.transaction
            }

            let resObj = await this.$store.dispatch('transaction/saveTransaction', data);

            if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;
            if(resObj.success) {
                this.resetForm();
                if (this.$route.path !== "/transaction") {
                    this.$router.push('/transaction');
                }
            }
        },
        resetForm(){
            this.selectedEmployee = null;
            this.selectedProcessBy = null;

            this.validation_errors = {};

            this.transaction = {
                id: null,
                code: '',
                employee_id: null,
                date: this.toISOLocal().substr(0, 10),
                tr_type: '',
                amount: 0,
                remark: '',
                process_by: null,
            };

            this.$refs.transactionForm.resetValidation();
            this.getTransactionCode();
        },
        editTransaction(transaction){
            this.selectedProcessBy = {
                id          : transaction.process_by,
                display_name: transaction.process_by_name,
            }

            this.transaction = {
                id: transaction.id,
                code: transaction.code,
                employee_id: transaction.employee_id,
                date: transaction.date,
                tr_type: transaction.tr_type,
                amount: transaction.amount,
                remark: transaction.remark,
            }
        },
        async deleteTransaction(transaction){
            if (!confirm("Are you sure?")) return;

            await this.$store.dispatch("transaction/deleteTransaction", transaction.id);
            this.getTransactions();
        },
        async print(){
            const monthNames = this.monthNameArray();
            const d = new Date();

            let title = `Transaction of ${this.selectedEmployee.name} (${monthNames[d.getMonth()]}, ${d.getFullYear()})`;

            let company_profile = this.$store.getters['company/company'];

            // Get HTML to print from element
            let prtHtml = '';
            for (const node of [...document.querySelectorAll('#reportContent table')]) {
                prtHtml += node.outerHTML;
            }

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                    <div class="container container--fluid">
                        <div class="row row--dense">
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+company_profile.logo}">
                            </div>
                            <div class="col-8 px-2 text-center">
                                <span class="font-weight-black">
                                    ${company_profile.name}
                                </span>
                                <br>
                                <span>${company_profile.address}</span>
                                <br>
                                <span>
                                    Email:${company_profile.email}, Contact:${company_profile.phone}
                                </span>
                            </div>
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+'linkup.png'}">
                            </div>
                            <div class="col-12 px-2 text-center">
                                <p class="font-weight-black custom_header">
                                    ${title}
                                </p>
                            </div>
                            <div class="col col-12 px-2">
                                ${prtHtml}
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`);

            WinPrint.document.head.innerHTML += `
                <style>
                    table, th, td {
                        border: 1px solid #dee2e6;
                        border-collapse: collapse;
                        padding: 0 !important;
                        font-size: 11px;
                    }

                    table{
                        width: 100%;
                    }

                    .v-application .text-start {
                        text-align: center !important;
                    }

                    .v-data-table-header th.sortable {
                        text-align: center !important;
                        background: #4caf7773;
                    }

                    .custom_header{
                        background-color: #0000001f;
                        border-radius: 4px;
                        margin-bottom: 5px !important;
                    }

                    @media print {
                        table { page-break-after:auto }
                        tr    { page-break-inside:avoid; page-break-after:auto }
                        td    { page-break-inside:avoid; page-break-after:auto }
                        thead { display:table-header-group }
                        tfoot { display:table-footer-group }
                    }
                </style>
            `;

            let rows = WinPrint.document.querySelectorAll('table tr');
            rows.forEach(row => {
                row.lastChild.remove();
            })

            WinPrint.focus();
            WinPrint.document.close();
            await new Promise(resolve => setTimeout(resolve, 2000));
            WinPrint.print();
            WinPrint.close();
        }
    },
}
</script>

<style scoped>
>>>.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td{
    height: 0 !important;
    font-size: 11px;
}
</style>